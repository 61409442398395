var exports = {};
var b = Symbol.for("react.element"),
    c = Symbol.for("react.portal"),
    d = Symbol.for("react.fragment"),
    e = Symbol.for("react.strict_mode"),
    f = Symbol.for("react.profiler"),
    g = Symbol.for("react.provider"),
    h = Symbol.for("react.context"),
    k = Symbol.for("react.server_context"),
    l = Symbol.for("react.forward_ref"),
    m = Symbol.for("react.suspense"),
    n = Symbol.for("react.suspense_list"),
    p = Symbol.for("react.memo"),
    q = Symbol.for("react.lazy"),
    t = Symbol.for("react.offscreen"),
    u;
u = Symbol.for("react.module.reference");

function v(a) {
  if ("object" === typeof a && null !== a) {
    var r = a.$$typeof;

    switch (r) {
      case b:
        switch (a = a.type, a) {
          case d:
          case f:
          case e:
          case m:
          case n:
            return a;

          default:
            switch (a = a && a.$$typeof, a) {
              case k:
              case h:
              case l:
              case q:
              case p:
              case g:
                return a;

              default:
                return r;
            }

        }

      case c:
        return r;
    }
  }
}

exports.ContextConsumer = h;
exports.ContextProvider = g;
exports.Element = b;
exports.ForwardRef = l;
exports.Fragment = d;
exports.Lazy = q;
exports.Memo = p;
exports.Portal = c;
exports.Profiler = f;
exports.StrictMode = e;
exports.Suspense = m;
exports.SuspenseList = n;

exports.isAsyncMode = function () {
  return !1;
};

exports.isConcurrentMode = function () {
  return !1;
};

exports.isContextConsumer = function (a) {
  return v(a) === h;
};

exports.isContextProvider = function (a) {
  return v(a) === g;
};

exports.isElement = function (a) {
  return "object" === typeof a && null !== a && a.$$typeof === b;
};

exports.isForwardRef = function (a) {
  return v(a) === l;
};

exports.isFragment = function (a) {
  return v(a) === d;
};

exports.isLazy = function (a) {
  return v(a) === q;
};

exports.isMemo = function (a) {
  return v(a) === p;
};

exports.isPortal = function (a) {
  return v(a) === c;
};

exports.isProfiler = function (a) {
  return v(a) === f;
};

exports.isStrictMode = function (a) {
  return v(a) === e;
};

exports.isSuspense = function (a) {
  return v(a) === m;
};

exports.isSuspenseList = function (a) {
  return v(a) === n;
};

exports.isValidElementType = function (a) {
  return "string" === typeof a || "function" === typeof a || a === d || a === f || a === e || a === m || a === n || a === t || "object" === typeof a && null !== a && (a.$$typeof === q || a.$$typeof === p || a.$$typeof === g || a.$$typeof === h || a.$$typeof === l || a.$$typeof === u || void 0 !== a.getModuleId) ? !0 : !1;
};

exports.typeOf = v;
export default exports;